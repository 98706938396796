import React, { useState, useEffect } from "react";
import Retool from "react-retool";

if(window.location.hostname.split('.')[1] !== 'softinka'){
    window.location.href = 'https://softinka.app';
}

const codeProject = window.location.hostname.split('.')[0]; // ваш код проекта

const App = () => {
  // Создаем состояние для newData
  const [newData, setNewData] = useState({ content: "initial value" });
  
  // Делаем setNewData доступной глобально, чтобы можно было изменить sampleData извне
  useEffect(() => {
    window.setNewData = setNewData;
  }, []);

  return (
    <div>
      <Retool
        //url={`https://r.softinka.app/p/${codeProject}`}
        url={`https://r.softinka.app/app/bxbot`}
        data={newData} // Передаем состояние sampleData
        sandbox={"allow-scripts allow-same-origin"}
      />
    </div>
  );
};

export default App;
